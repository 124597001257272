import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, timer, merge } from 'rxjs';
import {
  map,
  catchError,
  switchMap,
  withLatestFrom,
  takeWhile,
  tap,
  filter,
  takeUntil,
} from 'rxjs/operators';
import { Message } from 'app/models';
import { ApiService } from 'store/api/api.service';
import * as fromRouterSelectors from 'store/router-store/router.selectors';
import * as MessageActions from './messages.actions';
import * as fromMessages from './messages.reducer';
import * as MonitoringActions from 'store/monitoring-goals-store/monitoring-goals.actions';
import * as AuthActions from 'store/auth-store/auth.actions';
import { AppStoreFacade } from 'store/app-store-facade/app-store.facade';

@Injectable()
export class MessagesEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<fromMessages.State | fromRouterSelectors.State>,
    private toastController: ToastController,
    private router: Router,
    private appStoreFacade: AppStoreFacade
  ) {}

  loadMessages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.loadMessages),
      switchMap(() =>
        this.apiService.getMessages().pipe(
          map((data) =>
            MessageActions.loadMessagesSuccess({
              messages: data.results,
              learningMessageCategories: data.learning_message_categories,
            })
          ),
          catchError((error) => of(MessageActions.loadMessagesFailure({ error })))
        )
      )
    );
  });

  loadMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.loadMessage),
      switchMap((action) =>
        this.apiService.getMessage(action.id).pipe(
          map((message) => MessageActions.loadMessageSuccess({ message })),
          catchError((error) => of(MessageActions.loadMessageFailure({ error })))
        )
      )
    );
  });

  startPollingMessages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.startPollingMessages, AuthActions.signOutFailure),
      switchMap(() =>
        timer(0, 10000).pipe(
          withLatestFrom(this.store.pipe(select(fromMessages.selectPolling))),
          takeWhile(([, p]) => p),
          map(() => MessageActions.loadPollingMessages())
        )
      )
    );
  });

  stopPollingMessagesOnSignOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signOut),
      map(() => MessageActions.stopPollingMessages())
    );
  });

  loadPollingMessages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.loadPollingMessages),
      switchMap(() =>
        this.apiService.getMessages().pipe(
          map((data) =>
            MessageActions.loadPollingMessagesSuccess({
              messages: data.results,
              learningMessageCategories: data.learning_message_categories,
            })
          ),
          catchError((error) => of(MessageActions.loadPollingMessagesFailure({ error }))),
          // skips pending messages request if the user signs out or the app is offline
          takeUntil(
            merge(
              this.actions$.pipe(ofType(AuthActions.signOut)),
              this.appStoreFacade.isOffline$.pipe(filter((isOffline) => isOffline === true))
            )
          )
        )
      )
    );
  });

  showNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MessageActions.loadPollingMessagesSuccess),
        withLatestFrom(this.store.pipe(select(fromMessages.selectUnreadMessages))),
        map(([, messages]) => {
          const sendNotifications = localStorage.getItem('pro_sendNotifications')
            ? localStorage.getItem('pro_sendNotifications').split(',')
            : [];
          const unsendNotificaions = messages.filter((m) => !sendNotifications.includes(`${m.id}`));
          localStorage.setItem('pro_sendNotifications', messages.map((m) => m.id).toString());

          if (unsendNotificaions.length > 0) {
            // fetch monitoring-goals to refresh dashboard
            this.store.dispatch(MonitoringActions.loadMonitoringGoals());
          }

          return unsendNotificaions;
        }),
        withLatestFrom(this.store.pipe(select(fromRouterSelectors.selectUrl))),
        filter(
          ([m, u]) =>
            m.length > 0 &&
            !u.includes('inbox') &&
            !u.includes('message') &&
            !u.includes('onboarding') &&
            !u.includes('legal') &&
            !u.includes('two-fa-verification')
        ),
        tap(([messages]) => {
          if (messages[0]) {
            this.presentToast(messages[0]);
          }
        })
      );
    },
    { dispatch: false }
  );

  private async presentToast(message: Message) {
    const truncate = (t: string) => (t.split('').length > 63 ? `${t.substring(0, 60)}...` : t);
    const toast = await this.toastController.create({
      header: truncate(this.removeHtmlFromString(message.subject)),
      message: truncate(this.removeHtmlFromString(message.body)),
      position: 'top',
      color: 'light',
      duration: 7000,
      cssClass: 'message-toast',
      buttons: [
        {
          side: 'start',
          text: $localize`lesen`,
          role: 'read message',
          handler: () => {
            this.router.navigateByUrl(`app/message/${message.id}`);
          },
        },
        {
          icon: 'close',
          role: 'close',
        },
      ],
    });
    toast.present();
  }

  /**
   * Removes all html-tags from a string.
   * @param htmlString input string that may contain html
   * @returns string without html
   */
  private removeHtmlFromString(htmlString: string) {
    try {
      const div = document.createElement('div');
      div.innerHTML = htmlString;
      return div.textContent || div.innerText || htmlString;
    } catch (err) {
      return htmlString;
    }
  }
}
